export default class LocationManager
{
    constructor()
    {
        this.setGeolocation();
    }

    setGeolocation()
    {
        let geolocation = false;
        if (navigator.geolocation) {
            geolocation = true;
        }
        myApp.browserStorageService.setItem('geolocation', geolocation);
    }

    getDistanceBetweenUserAndDestination(destination)
    {
        const latitude = myApp.browserStorageService.getItem('latitude');
        const longitude = myApp.browserStorageService.getItem('longitude');
        const from = new google.maps.LatLng(latitude, longitude);

        return (google.maps.geometry.spherical.computeDistanceBetween(from, destination) / 1000).toFixed(2);
    }
}