var SorterCriteria = function(field, order, title, icon, type) {
    
    'use strict';
    
    this.field = '';
    this.order = 'asc';
    this.title = '';
    this.icon = '';
    this.type = '';
    
    this.initialize = function() {
        this.field = field;
        this.order = order;
        this.title = title;
        this.icon = icon;
        this.type = type;
    };
    
    this.initialize();
};

export default SorterCriteria;