export default class UserLocationManager
{
    constructor()
    {
        this.paramDistance = myApp.urlManager.getUrlParam('distance');
        this.searchBox = null;
        this.map = null;
        this.marker = null;
        this.initialize();
        this.eventDelegation();
    }

    initialize()
    {
        if (this.paramDistance && $('#user_location').length > 0) {
            if (window.parent.location.href.match(/&distance=/)) {
                if (typeof (history.pushState) != "undefined") {
                    var obj = {Title: document.title, Url: window.parent.location.pathname};
                    history.pushState(obj, obj.Title, obj.Url);
                } else {
                    window.parent.location = window.parent.location.pathname;
                }
            }
        }
        if (myApp.browserStorageService.getItem('realLocation')) {
            myApp.browserStorageService.setItem('realLocation', true);
            this.__setDropdownDistanceView();
        }
    }

    eventDelegation()
    {
        $('body').on('click', '[data-action="set-user-location"]', () =>
        {
            this.setUserLocation();
        });

        let modalId = '#user_location';
        let modal = document.querySelector(modalId);
        if (modal) {
            modal.addEventListener('custom-modal-shown', () => {
                this.showModal()
            });
        }
    }

    showModal()
    {
        $('#menu_overlay').fadeOut();

        if (this.map == null || this.searchBox == null) {
            this.initializeUserLocationMap();
            // this.initializeSearchBox();
        }
    }

    initializeUserLocationMap()
    {
        const latitudeStorage = myApp.browserStorageService.getItem('latitude');
        const longitudeStorage = myApp.browserStorageService.getItem('longitude');

        let latitude = 40.415363;
        let longitude = -3.707398;
        let isStatic = true;

        if (latitudeStorage !== null && longitudeStorage !== null) {
            latitude = latitudeStorage;
            longitude = longitudeStorage;
            isStatic = false;
        }

        this.setBrowserStorageLatLng(latitude, longitude);
        const latLng = new google.maps.LatLng(latitude, longitude);
        const mapOptions = {
            zoom: isStatic ? 4 : 13,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latLng,
            gestureHandling: 'greedy',
            mapId: myApp.config.parameters.googleMapsId
        };

        this.map = new google.maps.Map(document.getElementById('map-location'), mapOptions);
        const icon = {
            url: myApp.config.cdnPath.img + '/maps/marker-no-selected.svg',
            scaledSize: new google.maps.Size(27, 39)
        };
        this.marker = new google.maps.Marker({
            map: this.map,
            position: latLng,
            icon: icon,
            draggable: true
        });

        google.maps.event.addListener(this.marker, 'dragend', (e) => {
            this.setBrowserStorageLatLng(e.latLng.lat(), e.latLng.lng());
        });
        google.maps.event.addListener(this.map, 'click', (e) => {
            this.marker.setPosition(e.latLng);
            this.marker.setMap(this.map);
            this.setBrowserStorageLatLng(e.latLng.lat(), e.latLng.lng());
        });
        google.maps.event.trigger(this.map, "resize");
    }

    initializeSearchBox()
    {
        const input = document.getElementById("location-input");
        this.searchBox = new google.maps.places.SearchBox(input);

        this.searchBox.addListener("places_changed", () => {
            const places = this.searchBox.getPlaces();
            if (places.length !== 1) {
                input.classList.add("border-error");
                return;
            }
            input.classList.remove("border-error");

            let bounds = new google.maps.LatLngBounds();
            let currentPlace = places[0];
            if (currentPlace.geometry.viewport) {
                bounds.union(currentPlace.geometry.viewport);
            } else {
                bounds.extend(currentPlace.geometry.location);
            }
            this.marker.setPosition(currentPlace.geometry.location);
            this.map.fitBounds(bounds);

            this.setBrowserStorageLatLng(currentPlace.geometry.location.lat(), currentPlace.geometry.location.lng());
        });
    }

    setBrowserStorageLatLng(latitude, longitude)
    {
        myApp.browserStorageService.setItem('latitude', latitude);
        myApp.browserStorageService.setItem('longitude', longitude);
    }

    setUserLocation()
    {
        $('#menu_overlay').fadeOut();

        myApp.browserStorageService.setItem('realLocation', true);
        this.__setDropdownDistanceView();

        myApp.browserHistoryManager.removeState('#user_location');
        if (this.paramDistance && $('#user_location').length > 0) {
            if ($('#containerOrderMenu').is(":visible")) {
                $('#menu_overlay').fadeIn();
            }
        }
        myApp.book.availabilitySorter.getDestinations = true;

        if (myApp.deviceDetector.isSmScreen() || myApp.deviceDetector.isXsScreen()) {
            $('#containerOrderMenu .sorter_element[data-order="asc"][data-field="distance"]').click();
        } else {
            $('#central_content .sorter_element[data-order="asc"][data-field="distance"]').click();
        }
    };

    __setDropdownDistanceView()
    {
        const dropdownDistanceElement = $('.dropdownDistance');
        dropdownDistanceElement.addClass('dropdown-toggle');
        dropdownDistanceElement.data('toggle', 'dropdown');
        dropdownDistanceElement.attr('data-toggle', 'dropdown');
        dropdownDistanceElement.removeAttr('data-target');
    }
}